.customRButton {
    margin: 0px 5px !important;
    border-radius: 5px;
    border: 1px solid var(--Neutral-Extended-Borders, #d8dae5);
    background: var(--Principal-White, #fff);
}

.ant-radio-button-wrapper.customRButton::before {
    /*DELETE */
    content: '' !important;
    background-color: transparent !important;
    display: none !important;
}

.ant-radio-button-wrapper-checked.customRButton {
    border-radius: 5px !important;
    background: var(--Purple-Extended-P50, #f8f6ff) !important;
    border: 1px solid transparent !important;
    color: #8666ff !important;
}

.settingCard:hover {
    border: 1px solid var(--Neutral-Extended-Borders, #8666ff) !important;
}

.settingCard:hover .smallheader {
    color: #8666ff !important;
}
.ant-upload-wrapper,
.ant-upload {
    width: 100% !important;
}
/*eslint-disable-next-line*/
.customUpload {
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-items: left;
    align-content: left;
}

.ant-upload-list {
    width: 100% !important;
}

.ant-form-item-explain {
    padding-bottom: 10px !important;
}

.formCard {
    border: 2px solid #d8dae5 !important;
    cursor: pointer;
}
.formCard:hover {
    border: 2px solid var(--neutral-1) !important;
}
.formCardSelected {
    border: 2px solid var(--neutral-1) !important;
}

/* CREATE A TRIANGLE TO THE LEFT OF THE DIV USING WHITE COLOR LIKE BUBBLE CHAT TO THE MIDDLE OF THE DIV*/
.bubbleTriangle::before {
    content: '';
    position: absolute;
    top: 70%;
    left: 95px;
    transform: translateY(-70%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
}

.testBadge {
    width: 90px;
    border: 1px solid transparent !important;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55) !important;
}

.testBadge:is(:hover, :focus) {
    /* width: 97%;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55) !important; */
    border: 1px solid #8666ff !important;
}

.backTestMode {
    background: #def0ff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #eae5fe, #ffe1f0);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #eae5fe, #ffe1f0);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bsh {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.shake:not(:hover) {
    animation: shake 5s ease infinite;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-1px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(2px);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.fade-in-1 {
    animation-delay: 1s;
}

.fade-in-2 {
    animation-delay: 2s;
}

.fade-in-3 {
    animation-delay: 3s;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}