.bigheade {
    color: var(--neutral-extended-headlines, #101840);
    /* Headings/Big */
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    /* 125% */
}

.mainheader {
    color: var(--neutral-extended-headlines, #101840);
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.topheader {
    color: var(--neutral-extended-headlines, #101840);
    /* Headings/Main */
    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
}

.mediumheader {
    color: var(--neutral-extended-headlines, #101840);
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.smallheader {
    color: var(--neutral-extended-headlines, #101840);
    /* Headings/Extra Small */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.extrasmallheader {
    color: var(--neutral-extended-headlines, #101840);

    /* Headings/Extra Small */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.highlightheader {
    color: var(--neutral-extended-headlines, #101840);

    /* Headings/Low level */
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
}

.tableheader {
    color: var(--neutral-extended-description, #989bab);

    /* Headings/Table */
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.charthader {
    color: var(--principal-neutral, #474d66);
    /* Paragraph/Big-P */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.bigparagraph {
    color: var(--principal-neutral, #474d66);
    /* Paragraph/Big Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.bigparagraphbold {
    color: var(--principal-neutral, #474d66);

    /* Paragraph/Big Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.mediumparagraph {
    color: var(--principal-neutral, #474d66);

    /* Paragraph/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.mediumparagraphbold {
    color: var(--principal-neutral, #474d66);

    /* Paragraph/Medium Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}
.smallparagraphbold {
    color: var(--principal-neutral, #474d66);

    /* Paragraph/Small Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}
.smallparagraph {
    color: var(--principal-neutral, #474d66);

    /* Paragraph/Small */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.extrasmallparagraph {
    color: var(--principal-neutral, #474d66);

    /* Extra small paragraph */
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 177.778% */
}
.codeminimal {
    border-radius: 4px;
    border: 1px solid var(--neutral-borders, #d8dae5);
    background: var(--neutral-background-lighter, #fafbff);
    color: var(--neutral-body, #474d66);
    /* Code/Code minimal */
    font-family: Space Mono;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.codedefault {
    border-radius: 4px;
    border: 1px solid var(--blue-b-200, #d6e0ff);
    background: var(--blue-b-50, #f3f6ff);
    color: var(--blue-principal, #6b7cf9);
    /* Code/Code default */
    font-family: Space Mono;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}
.link {
    cursor: pointer;
    text-decoration: underline;
}

.dangerHeading {
    color: var(--red-extended-r-500, #a73636);

    /* Headings/Extra Small */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.ant-picker-presets {
    color: var(--neutral-1) !important;

    /* Paragraph/Small */
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    padding: 10px 0px !important;
}
