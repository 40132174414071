.purple,
.primary {
    color: #8666ff;
}

.purplebg {
    background-color: #8666ff;
}

.neutral {
    color: #474d66;
}

.bglighter {
    background-color: #fafbff;
}

.neutralbg {
    background-color: #474d66;
}

.white {
    color: #ffffff;
}

.whitebg {
    background-color: #ffffff;
}

.green {
    color: #52bd94;
}

.greenbg {
    background-color: #52bd94;
}

.yellow {
    color: #ffb020;
}

.yellowbg {
    background-color: #ffb020;
}

.red,
.danger {
    color: #d14343;
}

.redbg,
.dangerbg {
    background-color: #d14343;
}

.blue {
    color: #6b7cf9;
}

.bluebg {
    background-color: #6b7cf9;
}

.teal {
    color: #00b2b2;
}

.tealbg {
    background-color: #00b2b2;
}

.pink {
    color: #ed55c2;
}

.pinkbg {
    background-color: #ed55c2;
}

.orange {
    color: #de7548;
}

.orangebg {
    background-color: #de7548;
}

.gradientbg {
    background: linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%);
}

.descriptions {
    color: #989bab;
}

.descriptionsbg {
    background-color: #989bab;
}

.warning {
    color: #eaa98c;
}
