body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
    color: #474d66 !important;
    font-weight: 500 !important;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.ant-upload-select {
    width: 100% !important;
    height: 100% !important;
}

.ant-form-item-explain {
    font-size: 10px !important;
    margin-left: 3px !important;
}

.primary-color {
    color: #8666ff !important;
}
